import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { PurchaseType } from 'Definitions/Purchase.type.ts'
import { GroupType } from 'Types/global.types.ts'
import { CustomFileType } from 'Definitions/CustomFile.type.ts'

import { CUSTOMS_API_ROOT } from 'Constants/Global.admin.constants.ts'

import { getAccessToken, getAxiosConfig } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

export const searchCustomsPurchases = createAsyncThunk(
  'customs/searchCustomsPurchases',
  async ({
    param = '',
    pageIndex,
    pageSize,
    sort,
  }: {
    param: string
    pageIndex: number
    pageSize: number
    sort: 'ORDER_NUMBER' | 'CREATED_AT'
  }) => {
    let url = `${CUSTOMS_API_ROOT}/purchase/search`
    const queryParams = []

    if (param) {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (sort) {
      queryParams.push(`sort=${encodeURIComponent(sort)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: PurchaseType[]; total: number }> = await axios.get(url, config)
      return {
        purchases: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getCustomsPurchase = createAsyncThunk('customs/getCustomsPurchase', async (purchaseId: string) => {
  const url = `${CUSTOMS_API_ROOT}/purchase/${purchaseId}`

  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: PurchaseType }> = await axios.get(url, config)
    return {
      purchaseDetails: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const getCustomsPurchaseCustomFiles = createAsyncThunk(
  'customs/getCustomsPurchaseCustomFiles',
  async ({ entityId, group }: { entityId: string; group: GroupType }) => {
    const url = `${CUSTOMS_API_ROOT}/purchase/${entityId}/custom-file?group=${group}`

    try {
      const { data }: AxiosResponse<{ data: CustomFileType[] }> = await axios.get(url, getAxiosConfig())
      return {
        customFiles: data.data,
        group,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
