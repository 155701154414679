import axios from 'axios'
import { toast } from 'react-toastify'
import i18n from 'i18next'

export const handleError = (error: unknown, isNotify?: boolean) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (error.response.data?.error?.type === 'USER_BLOCKED' && window.location.pathname !== '/login') {
        window.location.href = '/logout'
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      } else if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        error.response.data?.error?.type === 'ADMIN_DOES_NOT_EXIST' &&
        window.location.pathname !== '/admin/logout'
      ) {
        window.location.href = '/admin/logout'
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      } else if (error.response.data?.error?.type === 'USER_BLOCKED') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        throw error.response.data?.error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      } else if (error.response.data?.error?.type === 'USER_NOT_VERIFIED') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        throw error.response.data?.error
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const errorMessage: string = error.response.data?.error?.message

        let translatedErrorMessage = ''
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const errorType = error?.response?.data?.error.type

        switch (errorType) {
          case 'FACTURE_PAID': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.FACTURE_PAID`)
            break
          }
          case 'SOMETHING_WENT_WRONG': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.SOMETHING_WENT_WRONG`)
            break
          }
          case 'INVALID_DATA': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.INVALID_DATA`)
            break
          }
          case 'EMAIL_ALREADY_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.EMAIL_ALREADY_EXISTS`)
            break
          }
          case 'BUYER_ALREADY_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.BUYER_ALREADY_EXISTS`)
            break
          }
          case 'SELLER_ALREADY_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.SELLER_ALREADY_EXISTS`)
            break
          }
          case 'USERNAME_ALREADY_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.USERNAME_ALREADY_EXISTS`)
            break
          }
          case 'USER_CUSTOM_FILE_NAME_ALREADY_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.USER_CUSTOM_FILE_NAME_ALREADY_EXISTS`)
            break
          }
          case 'VIN_ALREADY_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.VIN_ALREADY_EXISTS`)
            break
          }
          case 'PURCHASE_CUSTOM_FILE_NAME_ALREADY_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.PURCHASE_CUSTOM_FILE_NAME_ALREADY_EXISTS`)
            break
          }
          case 'STORAGE_PLACE_ALREADY_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.STORAGE_PLACE_ALREADY_EXISTS`)
            break
          }
          case 'CUSTOMS_CROSSING_ALREADY_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.CUSTOMS_CROSSING_ALREADY_EXISTS`)
            break
          }
          case 'SHIPPING_ADDRESS_DOES_NOT_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.SHIPPING_ADDRESS_DOES_NOT_EXISTS`)
            break
          }
          case 'USER_DOES_NOT_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.USER_DOES_NOT_EXISTS`)
            break
          }
          case 'SELLER_DOES_NOT_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.SELLER_DOES_NOT_EXISTS`)
            break
          }
          case 'BUYER_DOES_NOT_EXISTS': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.BUYER_DOES_NOT_EXISTS`)
            break
          }
          case 'INVALID_VERIFICATION_TOKEN': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.INVALID_VERIFICATION_TOKEN`)
            break
          }
          case 'NOT_FOUND': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.NOT_FOUND`)
            break
          }
          case 'INVALID_EORI_NUMBER': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.INVALID_EORI_NUMBER`)
            break
          }
          case 'PAYMENT_SERVICE_ISSUE': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.PAYMENT_SERVICE_ISSUE`)
            break
          }
          case 'FORBIDDEN': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.FORBIDDEN`)
            break
          }
          case 'INVALID_DATA_SENT': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.INVALID_DATA_SENT`)
            break
          }
          case 'USER_ALREADY_VERIFIED': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.USER_ALREADY_VERIFIED`)
            break
          }
          case 'CERTIFICATE_ALREADY_PAID': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.CERTIFICATE_ALREADY_PAID`)
            break
          }
          case 'EUR1_NOT_AVAILABLE': {
            translatedErrorMessage = i18n.t(`translation:common.error_types.EUR1_NOT_AVAILABLE`)
            break
          }
          default: {
            translatedErrorMessage = errorMessage
          }
        }

        isNotify && toast.error(translatedErrorMessage)
        throw errorMessage
      }
    } else if (error.request) {
      throw error.request
    } else {
      throw error.message
    }
  } else {
    throw error
  }
}
