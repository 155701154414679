import { createSlice } from '@reduxjs/toolkit'
import { SellerType } from 'Types/global.types.ts'
import { getCustomsSellers } from 'Api/customs/customs.sellers.service.ts'

interface InitialStateProps {
  customsSellerList: SellerType[]
  loading: boolean
  total: number
  pageSize: number
  param: string
}

const initialState: InitialStateProps = {
  customsSellerList: [],
  loading: false,
  total: 0,
  pageSize: 20,
  param: '',
}

const customsSellersSlice = createSlice({
  name: 'customsSellers',
  initialState,
  reducers: {
    setParam: (state, action) => {
      state.param = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomsSellers.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsSellerList = action.payload.sellers
          state.loading = false
        }
      })
      .addCase(getCustomsSellers.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCustomsSellers.pending, (state) => {
        state.loading = true
      })
  },
})

const { actions } = customsSellersSlice
export const { setParam } = actions
export default customsSellersSlice.reducer
