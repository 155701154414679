import { createSlice } from '@reduxjs/toolkit'

import { PurchaseType } from 'Definitions/Purchase.type.ts'
import { DocumentGroup } from 'Types/admin/admin'
import { CustomFileType } from 'Definitions/CustomFile.type.ts'

import { getCustomsPurchase, getCustomsPurchaseCustomFiles } from 'Api/customs/customs.purchases.service.ts'

interface InitialStateProps {
  customsPurchaseDetails: PurchaseType
  loading: boolean
  purchaseCustomFiles: CustomFileType[]
  purchaseCarCustomFiles: CustomFileType[]
  purchaseUserCustomFiles: CustomFileType[]
}

const initialState: InitialStateProps = {
  customsPurchaseDetails: null,
  loading: false,
  purchaseCustomFiles: [],
  purchaseCarCustomFiles: [],
  purchaseUserCustomFiles: [],
}

const customsPurchaseDetailsSlice = createSlice({
  name: 'customsPurchaseDetails',
  initialState,
  reducers: {
    resetCustomsPurchaseDetailsState: (state) => {
      state.customsPurchaseDetails = null
      state.purchaseCustomFiles = []
      state.purchaseCarCustomFiles = []
      state.purchaseUserCustomFiles = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomsPurchase.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsPurchaseDetails = action.payload.purchaseDetails
          state.loading = false
        }
      })
      .addCase(getCustomsPurchase.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCustomsPurchase.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomsPurchaseCustomFiles.fulfilled, (state, action) => {
        if (action.payload) {
          const { group } = action.payload
          switch (group as DocumentGroup) {
            case 'CERTIFICATE_DOCS': {
              state.purchaseCustomFiles = action.payload.customFiles
              break
            }
            case 'USER_DOCS': {
              state.purchaseUserCustomFiles = action.payload.customFiles
              break
            }
            case 'CAR_DOCS': {
              state.purchaseCarCustomFiles = action.payload.customFiles
              break
            }
          }
        }
      })
  },
})

const { actions } = customsPurchaseDetailsSlice
export const { resetCustomsPurchaseDetailsState } = actions
export default customsPurchaseDetailsSlice.reducer
