import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BlacklistType } from 'Types/admin/admin'

import { createBlacklist, getBlackList, removeBlacklist, updateBlacklist } from 'Api/admin/admin.blackList.service.ts'

interface InitialStateProps {
  blacklist: BlacklistType[]
  loading: boolean
  total: number
  pageSize: number
  param: string
}

const initialState: InitialStateProps = {
  blacklist: [],
  loading: false,
  total: 0,
  pageSize: 20,
  param: '',
}

const adminBlacklistSlice = createSlice({
  name: 'blacklistSlice',
  initialState,
  reducers: {
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlackList.fulfilled, (state, action) => {
        if (action.payload) {
          state.blacklist = action.payload.blacklistAll
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getBlackList.pending, (state) => {
        state.loading = true
      })
      .addCase(getBlackList.rejected, (state) => {
        state.loading = false
      })
      .addCase(createBlacklist.fulfilled, (state, action) => {
        if (action.payload) {
          state.blacklist.unshift(action.payload.blacklist)
          state.total += 1
        }
      })
      .addCase(updateBlacklist.fulfilled, (state, action) => {
        if (action.payload) {
          state.blacklist = state.blacklist.map((blacklist) => {
            if (blacklist.id === action.payload.blacklist.id) {
              return action.payload.blacklist
            } else {
              return blacklist
            }
          })
        }
      })
      .addCase(removeBlacklist.fulfilled, (state, action) => {
        if (action.payload.deleted) {
          state.blacklist = state.blacklist.filter((blacklist) => blacklist.id !== action.payload.id)
          state.total -= 1
        }
      })
  },
})

const { actions } = adminBlacklistSlice

export const { setParam } = actions

export default adminBlacklistSlice.reducer
