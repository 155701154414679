import { createSlice } from '@reduxjs/toolkit'

import { OrderType } from 'Definitions/Order.type.ts'
import { DocumentGroup } from 'Types/admin/admin'
import { CustomFileType } from 'Definitions/CustomFile.type.ts'

import { getCustomsOrder, getCustomsOrderCustomFiles } from 'Api/customs/customs.orders.service.ts'

interface InitialStateProps {
  customsOrderDetails: OrderType
  loading: boolean
  orderCustomFiles: CustomFileType[]
  orderCarCustomFiles: CustomFileType[]
  orderUserCustomFiles: CustomFileType[]
}

const initialState: InitialStateProps = {
  customsOrderDetails: null,
  loading: false,
  orderCustomFiles: [],
  orderCarCustomFiles: [],
  orderUserCustomFiles: [],
}

const customsOrderDetailsSlice = createSlice({
  name: 'customsOrderDetails',
  initialState,
  reducers: {
    resetCustomsOrderDetailsState: (state) => {
      state.customsOrderDetails = null
      state.orderCustomFiles = []
      state.orderCarCustomFiles = []
      state.orderUserCustomFiles = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomsOrder.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsOrderDetails = action.payload.orderDetails
          state.loading = false
        }
      })
      .addCase(getCustomsOrder.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCustomsOrder.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomsOrderCustomFiles.fulfilled, (state, action) => {
        if (action.payload) {
          const { group } = action.payload
          switch (group as DocumentGroup) {
            case 'CERTIFICATE_DOCS': {
              state.orderCustomFiles = action.payload.customFiles
              break
            }
            case 'USER_DOCS': {
              state.orderUserCustomFiles = action.payload.customFiles
              break
            }
            case 'CAR_DOCS': {
              state.orderCarCustomFiles = action.payload.customFiles
              break
            }
          }
        }
      })
  },
})

const { actions } = customsOrderDetailsSlice
export const { resetCustomsOrderDetailsState } = actions
export default customsOrderDetailsSlice.reducer
