import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { OrderType } from 'Definitions/Order.type.ts'

import { searchCustomsOrders } from 'Api/customs/customs.orders.service.ts'

interface InitialStateProps {
  customsOrderList: OrderType[]
  loading: boolean
  total: number
  pageSize: number
  param: string
  sort: 'COMMAND' | 'CREATED_AT'
  order: 'ASC' | 'DESC'
}

const initialState: InitialStateProps = {
  customsOrderList: [],
  loading: false,
  total: 0,
  pageSize: 20,
  param: '',
  sort: 'CREATED_AT',
  order: 'DESC',
}

const customsOrdersSlice = createSlice({
  name: 'customsOrders',
  initialState,
  reducers: {
    setParam: (state, action) => {
      state.param = action.payload
    },
    setOrder: (state, action: PayloadAction<'ASC' | 'DESC'>) => {
      if (action.payload) {
        state.order = action.payload
      }
    },
    setSort: (state, action: PayloadAction<'COMMAND' | 'CREATED_AT'>) => {
      if (action.payload) {
        state.sort = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCustomsOrders.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsOrderList = action.payload.orders
          state.total = action.payload.total
          state.loading = false
        }
      })
      .addCase(searchCustomsOrders.rejected, (state) => {
        state.loading = false
      })
      .addCase(searchCustomsOrders.pending, (state) => {
        state.loading = true
      })
  },
})

const { actions } = customsOrdersSlice
export const { setParam, setOrder, setSort } = actions
export default customsOrdersSlice.reducer
