import { DefaultTheme } from 'styled-components'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      primary: {
        default: string
        light: string
        medium: string
        strong: string
        dark: string
      }
      secondary: {
        extraDark: string
        dark: string
        medium: string
        light: string
      }
      tertiary: {
        dark: string
        medium: string
        light: string
      }
      situational: {
        success: string
        warning: string
        error: string
      }
      state: {
        success: string
        successLight: string
      }
      grays: {
        gray900: string
        gray800: string
        gray700: string
        gray600: string
        gray500: string
        gray400: string
        gray300: string
        gray200: string
        gray100: string
        white: string
        black: string
      }
    }
    fonts: {
      Inter: string
    }
  }
}

export const theme: DefaultTheme = {
  colors: {
    primary: {
      default: '#5352ED',
      light: '#EEEEFD',
      medium: '#CBCBFA',
      strong: '#9897F4',
      dark: '#3837B4',
    },
    secondary: {
      extraDark: '#e02231',
      dark: '#FF4757',
      medium: '#FF919A',
      light: '#FFC8CD',
    },
    tertiary: {
      dark: '#2F3542',
      medium: '#4C5363',
      light: '#F1F2F6',
    },
    situational: {
      success: '#80C570',
      warning: '#F5BA5C',
      error: '#E24840',
    },
    state: {
      success: '#83c775',
      successLight: '#c4f7ba',
    },
    grays: {
      gray900: '#0F1729',
      gray800: '#1B283A',
      gray700: '#333F55',
      gray600: '#455368',
      gray500: '#656F86',
      gray400: '#97A1B4',
      gray300: '#CFD5DD',
      gray200: '#EAECF0',
      gray100: '#F2F4F7',
      white: '#ffffff',
      black: '#000000',
    },
  },
  fonts: {
    Inter: 'Inter',
  },
}
