import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
// import jwtDecode from 'jwt-decode'

interface JwtTokenPayload {
  exp: string
  iat: string
  id: string
  type: 'PHYSICAL' | 'LEGAL' | 'FORWARDER' | 'SUPER' | 'REGULAR'
}

export const setAccessToken = (token: string, days: number): void => {
  Cookies.set('eot', token, { expires: days })
}

export const getAccessToken = (): string | undefined => Cookies.get('eot')

export const isAuthenticated = (): boolean => !!getAccessToken()

export const getUserRole = () => {
  const isToken = !!getAccessToken()
  if (isToken) {
    const token = getAccessToken()
    const decodeToken: JwtTokenPayload = jwtDecode(token)
    const { type } = decodeToken
    return type || ''
  }
  return ''
}

export const removeAccessToken = () => Cookies.remove('eot')

export const setTempAccessToken = (token: string) => {
  Cookies.set('tempEot', token, { expires: 1 })
}

export const getTempAccessToken = () => Cookies.get('tempEot')

export const removeTempAccessToken = () => Cookies.remove('tempEot')

export const getAxiosConfig = () => {
  const token = getAccessToken()
  return {
    headers: {
      Authorization: token,
    },
  }
}

export const getAxiosBlobConfig = () => {
  const token = getAccessToken()
  return {
    responseType: 'blob',
    headers: {
      Authorization: token,
    },
  }
}
