import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { FormikValues } from 'formik'

import { PURCHASE_BASE_API_URL } from 'Constants/Global.constants.ts'

import { PaginationProps, PurchaseResponseType } from 'Types/global.types.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get a list of purchases
 */
export const getPurchases = createAsyncThunk(
  'purchase/getPurchases',
  async ({ pageIndex, pageSize, param }: PaginationProps) => {
    const token = getAccessToken()
    const url = `${PURCHASE_BASE_API_URL}?page=${pageIndex}&limit=${pageSize}&param=${param}`

    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<PurchaseResponseType> = await axios.get(url, config)
      return {
        purchases: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Create a new Purchase item
 */
export const createPurchase = createAsyncThunk('purchase/createPurchase', async (options: FormikValues) => {
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  }

  const { data }: AxiosResponse<PurchaseResponseType> = await axios.post(PURCHASE_BASE_API_URL, options, config)
  return data.data
})

/**
 * Upload a new Swift document
 */
export const uploadSwift = createAsyncThunk(
  'purchase/uploadSwift',
  async ({ id, formData }: { id: string; formData: FormData }) => {
    const url = `${PURCHASE_BASE_API_URL}/${id}/swift`
    const token = getAccessToken()

    const config = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data }: AxiosResponse<PurchaseResponseType> = await axios.put(url, formData, config)
      return data.data
    } catch (error) {
      handleError(error)
    }
  }
)
