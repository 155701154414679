import styled, { DefaultTheme, keyframes } from 'styled-components'
import { ButtonProps, ButtonType, ButtonTypeNames } from 'Types/button.types.ts'

interface ButtonThemeProps {
  theme: DefaultTheme
  buttonType?: ButtonTypeNames
}

const switchColorByType = (props: ButtonThemeProps) => {
  switch (props.buttonType) {
    case ButtonType.Primary:
      return `
        background-color: ${props.theme.colors.primary.default};
        color: ${props.theme.colors.grays.white};
        &:hover {
          background-color: ${props.theme.colors.primary.dark};
        }
      `
    case ButtonType.PrimaryMinimal:
      return `
        background-color: ${props.theme.colors.primary.default};
        color: ${props.theme.colors.grays.white};
        padding: 3px 18px;
        min-height: 22px;
        font-size: 12px;
        &:hover {
          background-color: ${props.theme.colors.primary.dark};
        }
      `
    case ButtonType.Secondary:
      return `
        background-color: ${props.theme.colors.grays.gray100};
        color: ${props.theme.colors.grays.gray900};
        &:hover {
          background-color: ${props.theme.colors.grays.gray200};
        }
      `
    case ButtonType.SecondaryTransparent:
      return `
        background-color: transparent;
        color: ${props.theme.colors.grays.gray900};
        border: 1px solid ${props.theme.colors.grays.gray300};
        &:hover {
          background-color: transparent;
        }
      `
    case ButtonType.Error:
      return `
        background-color: ${props.theme.colors.secondary.dark};
        color: ${props.theme.colors.grays.white};
        &:hover {
          background-color: ${props.theme.colors.secondary.extraDark};
        }
      `
    case ButtonType.Disabled:
      return `
        background-color: ${props.theme.colors.grays.gray200};
        color: ${props.theme.colors.grays.gray400};
      `
    case ButtonType.Transparent:
      return `
        border: 1px solid ${props.theme.colors.primary.default};
        background-color: transparent;
        color: ${props.theme.colors.primary.default};
        &:hover {
          border: 1px solid ${props.theme.colors.primary.dark};
          color: ${props.theme.colors.primary.dark};
        }
      `
    case ButtonType.TransparentMinimal:
      return `
        border: 1px solid ${props.theme.colors.primary.default};
        background-color: transparent;
        color: ${props.theme.colors.primary.default};
        padding: 3px 18px;
        min-height: 22px;
        font-size: 12px;
        &:hover {
          border: 1px solid ${props.theme.colors.primary.dark};
          color: ${props.theme.colors.primary.dark};
        }
      `
    case ButtonType.successMinimal:
      return `
        border: 1px solid ${props.theme.colors.state.success};
        background-color: ${props.theme.colors.state.successLight};
        color: ${props.theme.colors.grays.gray900};
        padding: 3px 18px;
        min-height: 22px;
        font-size: 12px;
        &:hover {
          background-color: ${props.theme.colors.state.success};
        }
      `
    case ButtonType.Link:
      return `
        border: none;
        color: ${props.theme.colors.primary.default};
        background: transparent;
        padding: 0;
        margin: 0;
        width: auto;
      `
    case ButtonType.Square:
      return `
        background-color: ${props.theme.colors.primary.default};
        padding: 0;
        width: 2.5rem;
        height: 2.5rem;
      `
    case ButtonType.Download:
      return `
        border-radius: 3px;
        background-color: #F5F5F9;
        justify-content: space-between;
        padding: 0.875rem;
      `
    case ButtonType.MarketingPrimary:
      return `
        background-color: ${props.theme.colors.primary.default};
        color: ${props.theme.colors.grays.white};
        border-radius: 8px;
        &:hover {
          background-color: ${props.theme.colors.primary.dark};
        }
      `
    case ButtonType.MarketingSecondary:
      return `
        min-width: 305px;
        padding: 15px 32px;
        flex-shrink: 0;
        border-radius: 16px;
        color: ${props.theme.colors.primary.default};
        font-size: 20px;
        line-height: 22px;
        font-weight: 600;
      `
    case ButtonType.MarketingTransparent:
      return `
        padding: 8px 28px;
        border-radius: 8px;
        color: ${props.theme.colors.primary.default};
        background: transparent;
        border: 1px solid var(--primary-main, #5352ED);
        font-weight: 500;
        width: 100%;
      `
    case ButtonType.MarketingTransparentMinimal:
      return `
        border: 1px solid ${props.theme.colors.primary.default};
        background-color: transparent;
        color: ${props.theme.colors.primary.default};
        padding: 8px 28px;
        border-radius: 8px;
        font-size: 14px;
        &:hover {
          border: 1px solid ${props.theme.colors.primary.dark};
          color: ${props.theme.colors.primary.dark};
        }        
      `
    default:
      return `
        color: ${props.theme.colors.grays.white};
        background-color: ${props.theme.colors.primary.default};
        &:hover {
          background-color: ${props.theme.colors.primary.dark};
        }
      `
  }
}

export const StyledButton = styled.button<ButtonProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 14px;
  border: none;
  padding: 8px 28px;
  min-height: 38px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  font-family: Inter, sans-serif;
  ${switchColorByType}

  &:disabled {
    background-color: ${(props) => (props.buttonType === 'link' ? 'transparent' : props.theme.colors.grays.gray200)};
    color: ${(props) => props.theme.colors.grays.gray400};
    cursor: default;
    border-color: transparent;
  }

  .children-content {
    flex: 1;
  }
`

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${spinAnimation} 2s infinite linear;
`
