import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { DeblocageListResponseType, DeblocageResponse } from 'Types/admin/admin'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

export const getDeblocageList = createAsyncThunk(
  'admin/getDeblocageList',
  async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.DEBLOCAGE.ROOT}?page=${pageIndex}&limit=${pageSize}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<DeblocageListResponseType> = await axios.get(url, config)
      return {
        deblocageList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const createDeblocage = createAsyncThunk('admin/createDeblocage', async () => {
  const token = getAccessToken()
  const url = ADMIN_API_URL.DEBLOCAGE.ROOT
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<DeblocageResponse> = await axios.post(url, null, config)
    return {
      deblocage: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})
