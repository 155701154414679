import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationProps, PurchaseWithoutVatProps } from 'Types/global.types.ts'
import { createPurchase, getPurchases, uploadSwift } from 'Api/purchase.service.ts'

type PurchaseSliceProps = {
  purchaseList: PurchaseWithoutVatProps[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
  param: string
}

const initialState = {
  purchaseList: [],
  total: 0,
  loading: false,
  pageIndex: 0,
  pageSize: 20,
  param: '',
} as PurchaseSliceProps

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setPurchasePageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action) => {
      state.param = action.payload
      state.pageIndex = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPurchase.fulfilled, (state, action: PayloadAction<PurchaseWithoutVatProps>) => {
        if (action.payload) {
          state.purchaseList.unshift(action.payload)
          state.total += 1
          state.pageIndex = 0
        }
        state.loading = false
      })
      .addCase(createPurchase.pending, (state) => {
        state.loading = true
      })
      .addCase(createPurchase.rejected, (state) => {
        state.loading = false
      })
      .addCase(getPurchases.fulfilled, (state, action) => {
        if (action.payload) {
          // @ts-ignore
          state.purchaseList = action.payload.purchases
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getPurchases.pending, (state) => {
        state.loading = true
      })
      .addCase(getPurchases.rejected, (state) => {
        state.loading = false
      })
      .addCase(uploadSwift.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseList = state.purchaseList.map((purchase) => {
            if (purchase.id === action.payload.id) {
              return action.payload
            }
            return purchase
          })
        }
      })
  },
})

const { actions } = purchaseSlice
export const { setPurchasePageIndex, setParam } = actions
export default purchaseSlice.reducer
