import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PurchaseType } from 'Definitions/Purchase.type.ts'

import { searchCustomsPurchases } from 'Api/customs/customs.purchases.service.ts'

interface InitialStateProps {
  customsPurchaseList: PurchaseType[]
  loading: boolean
  total: number
  pageSize: number
  param: string
  sort: 'ORDER_NUMBER' | 'CREATED_AT'
  order: 'ASC' | 'DESC'
}

const initialState: InitialStateProps = {
  customsPurchaseList: [],
  loading: false,
  total: 0,
  pageSize: 20,
  param: '',
  sort: 'CREATED_AT',
  order: 'DESC',
}

const customsPurchasesSlice = createSlice({
  name: 'customsPurchases',
  initialState,
  reducers: {
    setParam: (state, action) => {
      state.param = action.payload
    },
    setOrder: (state, action: PayloadAction<'ASC' | 'DESC'>) => {
      if (action.payload) {
        state.order = action.payload
      }
    },
    setSort: (state, action: PayloadAction<'ORDER_NUMBER' | 'CREATED_AT'>) => {
      if (action.payload) {
        state.sort = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCustomsPurchases.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsPurchaseList = action.payload.purchases
          state.total = action.payload.total
          state.loading = false
        }
      })
      .addCase(searchCustomsPurchases.rejected, (state) => {
        state.loading = false
      })
      .addCase(searchCustomsPurchases.pending, (state) => {
        state.loading = true
      })
  },
})

const { actions } = customsPurchasesSlice
export const { setParam, setOrder, setSort } = actions
export default customsPurchasesSlice.reducer
