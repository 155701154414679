import { createSlice } from '@reduxjs/toolkit'
import { DeblocageType } from 'Types/admin/admin'
import { createDeblocage, getDeblocageList } from 'Api/admin/admin.deblocage.service.ts'

interface InitialStateProps {
  adminDeblocageList: DeblocageType[]
  loading: boolean
  total: number
  pageSize: number
}

const initialState: InitialStateProps = {
  adminDeblocageList: [],
  loading: false,
  total: 0,
  pageSize: 20,
}

const adminDeblocageSlice = createSlice({
  name: 'adminDeblocage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeblocageList.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminDeblocageList = action.payload.deblocageList
          state.loading = false
          state.total = action.payload.total
        }
      })
      .addCase(getDeblocageList.rejected, (state) => {
        state.loading = false
      })
      .addCase(getDeblocageList.pending, (state) => {
        state.loading = true
      })
      .addCase(createDeblocage.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminDeblocageList = [action.payload.deblocage, ...state.adminDeblocageList]
        }
      })
  },
})

export default adminDeblocageSlice.reducer
