import { createSlice } from '@reduxjs/toolkit'

import { searchForwarders } from 'Api/admin/admin.customers.service.ts'
import { UserApiType } from 'Schemas/user.types.ts'

interface InitialStateProps {
  adminForwarderList: UserApiType[]
  loading: boolean
  loaded: boolean
  total: number
  pageSize: number
  param: string
}

const initialState: InitialStateProps = {
  adminForwarderList: [],
  loading: false,
  loaded: false,
  total: 0,
  pageSize: 20,
  param: '',
}

const adminForwardersSlice = createSlice({
  name: 'adminForwarders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchForwarders.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminForwarderList = action.payload.customerList
          state.total = action.payload.total
          state.loaded = true
          state.loading = false
        }
      })
      .addCase(searchForwarders.pending, (state) => {
        state.loaded = false
        state.loading = true
      })
      .addCase(searchForwarders.rejected, (state) => {
        state.loaded = false
        state.loading = false
      })
  },
})

export default adminForwardersSlice.reducer
